document.addEventListener("DOMContentLoaded", function(){
  var year_trend_chart = document.getElementById('yoy_comparison_chart');
  if(typeof(year_trend_chart) != 'undefined' && year_trend_chart != null) {
    var current_year_trend_array = JSON.parse(year_trend_chart.dataset.current);
    var current_year_trend_data = current_year_trend_array.map(function(e) {
      return e;
    });
    var previous_year_trend_array = JSON.parse(year_trend_chart.dataset.previous);
    var previous_year_trend_data = previous_year_trend_array.map(function(e) {
      return e;
    });
    var options = {
      colors: ["#07818F", "#E8368F"],
      grid: {
        borderColor:'#edebeb',
        row: {
          colors: ['#f5f6fa', 'transparent'],
          opacity: 0.5
        },
        yaxis: {
          lines: {
            show: true
          },
        }
      },
      chart: {
        animations: {
          enabled: false
        },
        markers: {
          size: 4
        },
        type: 'line',
        height: 350,
        zoom: {
          enabled: false
        },
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '100%'
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth',
        width: 4
      },
      series: [{
        name: '2019',
        data: previous_year_trend_data
      },{
        name: '2020',
        data: current_year_trend_data
      }],
      xaxis: {
        type: 'category',
        labels: {
          show:true
        },
        axisTicks: {
          show: false
        }
      },
      yaxis: {
        labels: {
          show: true
        },
        max: 100,
        decimalsInFloat: 0
      },
      legend: {
        horizontalAlign: 'center',
        offsetY: 6
      }
    }

    var chart = new ApexCharts(document.querySelector("#yoy_comparison_chart"), options);
    chart.render();
  }
});
